import Tit from "@/components/tit";
import React, { useState, useEffect } from "react";
import { getDtu } from "@/api/rain";
import { keepTwoDecimalFull } from "@/utils/common";
import "./index.less";
const _ = require("lodash");
function Power(props) {
  const [liuliang, setLiuliang] = useState({}); // 流量
  const [deviceDetails, setDeviceDetails] = useState({}); // 发电机组
  useEffect(() => {
    getDtuFun();
    setInterval(() => {
      getDtuFun();
    }, 300000); //   5分钟接口单独请求一次
  }, []);
  const getDtuFun = () => {
    getDtu().then((res) => {
      // 获取流量
      console.log(res)
      if (res.data.code == 0) {
        if (res.data && res.data.status != 500) {
          const data = res.data.results.deviceDetails;
          try {
            data.generatorDetails = _.sortBy(
              data.generatorDetails,
              function (item) {
                return item.seqNo; //根据code对数据进行升序排序
              }
            );
            if (data.ecoLDetails && !data.ecoLDetails.length) {
              data.ecoLDetails.push({
                fqp: 0,
                flowValue: 0
              })
            }
            data.ecoLDetails.map((item, index) => {
              if (item.fqp == 0) {
                // 闭合
                item[`type`] = 1;
              } else if (item.fqp < 80) {
                // 半开
                item[`type`] = 2;
              } else {
                item[`type`] = 3;
              }
            });
            data.waterSupplyDetails.splice(0, 1)
            data.waterSupplyDetails.map((item, index) => {
              if (item.flowSeconds == 0) {
                // 闭合
                item[`type`] = 1;
              } else {
                item[`type`] = 3;
              }
            });
            data.ecoLDetails = _.sortBy(data.ecoLDetails, function (item) {
              return item.seqNo; //根据code对数据进行升序排序
            });
            res.data.results.powerGenerationDaily = String(
              (res.data.results.powerGenerationDaily / 10000).toFixed(2)
            ).split(".");
            setDeviceDetails(data);
            setLiuliang(res.data.results);
          } catch (err) {
            console.log(err);
          }
        }
      }
    });
  };
  return (
    <div className="power-wrap qcold-power-wrap">
      <Tit tit="发电机组 + 生态管">
        <div className="tit">
          {deviceDetails.deviceTotalFlow ? (
            <span>
              <i>{keepTwoDecimalFull(deviceDetails.deviceTotalFlowSeconds, 2)}</i>
            </span>
          ) : (
            <span>
              <i>0</i>
            </span>
          )}
          <span className="unit m-l-5">m³/s</span>
        </div>
      </Tit>
      <div className="power-main-wrap">
        <ul className="after fenche-old">
          {deviceDetails.generatorDetails &&
            deviceDetails.generatorDetails.map((item, index) => {
              return (
                <li className="fenche-li fenche-li-old" key={index}>
                  <div className="fenche-li-tit fz-12 flex flex-colum flex-a-c">
                    <div className="txt-c">装机容量</div>
                    <div className="fz-14 m-t-4  txt-c">{index < 1 ? 250 : 1250}<span className="m-l-3">kW</span></div>
                  </div>
                  <div className="fenche-li-hd fz-12 theme-bg-color">
                    {item.power ? item.power.toFixed(1) : 0}
                    <span className="m-l-3">kW</span>
                  </div>
                  <div
                    className={`fenche-li-bd ${item.power ? "active" : ""}`}
                  >

                    <div className={`op-6 ${item.power ? "active" : ""}`}>
                      <span className="fenche-ani"></span>
                      <span className="fenche-desc fenche-desc-2 fz-12">
                        {item.seqNo == 1 ? '生态机组' : item.seqNo + '号机组'}
                      </span>
                      <span className="fenche-desc fenche-desc-1 fz-12">
                        {index == 0 ? 'DN600' : 'DN1250'}
                      </span>
                    </div>
                    {item.power ? "" : <span className="top-icon"></span>}
                  </div>
                  <div className="fenche-li-ft fz-12 theme-bg-color">
                    {keepTwoDecimalFull(item.flowSeconds, 2)}
                    <span className="m-l-3">m³/s</span>
                  </div>
                </li>
              );
            })}
          {deviceDetails.waterSupplyDetails &&
            deviceDetails.waterSupplyDetails.map((item, index) => {
              return (
                <li className="fenche-li fenche-li-old" key={index}>
                  <div className="fenche-li-hd fz-12 theme-bg-color">
                    {item.deviceTitle}
                  </div>
                  <div className="fenche-li-bd">
                    {item.type == 1 ? (
                      <>
                        <div
                          className={`op-7 op-7-${item.type}`}
                          style={{ opacity: item.type == 1 ? 0.6 : 1 }}
                        >
                          <span className="fenche-desc fz-12">{item.deviceTag}</span>
                        </div>
                        {item.type != 1 ? (
                          ""
                        ) : (
                          <span className="top-icon"></span>
                        )}
                      </>
                    ) : (
                      <div className="h100 fenche-li-img">
                        <div className="fenche-li-img-gif"></div>
                        <div className="fenche-li-desc fz-12">{item.deviceTag}</div>
                      </div>
                    )}
                  </div>
                  <div className="fenche-li-ft fz-12 theme-bg-color">
                    {keepTwoDecimalFull(item.flowSeconds, 2)}
                    <span className="m-l-3">m³/s</span>
                  </div>
                </li>
              );
            })}
          {deviceDetails.ecoLDetails &&
            deviceDetails.ecoLDetails.map((item, index) => {
              return (
                <li className="fenche-li fenche-li-old" key={index}>
                  <div className="fenche-li-tit fz-12 flex flex-colum flex-a-c">
                    <div className="txt-c">年累计</div>
                    <div className="fz-14 m-t-4  txt-c">{keepTwoDecimalFull(item.flowAmount/10000, 2)}<span className="m-l-3">万m³</span></div>
                  </div>
                  <div className="fenche-li-hd fz-12 theme-bg-color">
                    {item.deviceTitle}
                  </div>
                  <div className="fenche-li-bd">
                    {item.type == 1 ? (
                      <>
                        <div
                          className={`op-7 op-7-${item.type}`}
                          style={{ opacity: item.type == 1 ? 0.6 : 1 }}
                        >
                          <span className="fenche-desc fz-12">{item.deviceTag}</span>
                        </div>
                        {item.type != 1 ? (
                          ""
                        ) : (
                          <span className="top-icon"></span>
                        )}
                      </>
                    ) : (
                      <div className="h100 fenche-li-img">
                        <div className="fenche-li-img-gif"></div>
                        <div className="fenche-li-desc fz-12">{item.deviceTag}</div>
                      </div>
                    )}
                  </div>
                  <div className="fenche-li-ft fz-12 theme-bg-color">
                    {keepTwoDecimalFull(item.flowSeconds, 2)}
                    <span className="m-l-3">m³/s</span>
                  </div>
                </li>
              );
            })}
        </ul>
        <div className="fenche-info-wrap flex flex-j-b m-t-5">
          <div className="fenche-info-main-wrap flex flex-j-b">
            <dl>
              <dt>当日发电量</dt>
              <dd>
                {liuliang.powerGenerationDaily &&
                  typeof liuliang.powerGenerationDaily == "object" ? (
                  <span>
                    <i>
                      {liuliang.powerGenerationDaily[0]}.
                      {liuliang.powerGenerationDaily[1]}
                    </i>
                  </span>
                ) : (
                  <span>
                    <i>0</i>
                  </span>
                )}
                <span className="unit">万kW·h</span>
              </dd>
            </dl>
            <dl>
              <dt>当月发电量</dt>
              <dd>
                {liuliang.powerGenerationMonthly ? (
                  <span>
                    <i>
                      {(liuliang.powerGenerationMonthly / 10000).toFixed(2)}
                    </i>
                  </span>
                ) : (
                  <span>
                    <i>0</i>
                  </span>
                )}
                <span className="unit">万kW·h</span>
              </dd>
            </dl>
            <dl>
              <dt>全年累计发电量</dt>
              <dd>
                {liuliang.powerGenerationYearly ? (
                  <span>
                    <i>{(liuliang.powerGenerationYearly / 10000).toFixed(2)}</i>
                  </span>
                ) : (
                  <span>
                    <i>0</i>
                  </span>
                )}
                <span className="unit">万kW·h</span>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Power;
