import React, { useState } from 'react';
import Style from './index.module.less';
import { settingData } from "@/store/raininfo";
import { UnorderedListOutlined, CloseOutlined } from '@ant-design/icons';
// import MyIcon from '../MyIcon';
import { convert } from "@/utils/common";
import { useRecoilValue } from "recoil";
import { GetRequest } from "@/utils/common";
const CHANNELID = GetRequest().channelid;
const setWaterQualityColor = (waterQuality) => {
  if (waterQuality < 3) {
    return 1
  }
  if (waterQuality < 5) {
    return 2
  }
  if (waterQuality < 5) {
    return 3
  }
}
function Navigation() {
  const settingDataReal = useRecoilValue(settingData); // 数据
  const [visibility, setVisibility] = useState(false) // 显示隐藏
  /**
   * 跳转界面 
   */
  const onClickItemFun = ({ url = '' }) => {
    window.location = url + '&dd_orientation=landscape'
  }
  /**
   * 图表点击 
   */
  const onClickFun = () => {
    setVisibility(!visibility)
  }
  return (
    <div className={`${Style.navigationWrapper} ${visibility && Style.active}`} onClick={onClickFun}>
      <UnorderedListOutlined type="ic_mainpage" className={`${visibility && Style.arcuHide} ${Style.static}`} />
      <CloseOutlined className={`${!visibility && Style.arcuShow} ${Style.close} fz-18`} />
      <div className={`${Style.messangersBlock} ${visibility && Style.active}`}>
        <div className={`${Style.messangersListContainer}`}>
          <ul className={`${Style.messangersListContainer} ${Style.arcuDowntoup}`}>
            {CHANNELID != '2b6def61-42eb-4ecc-ac38-ab9ecd079bca' && CHANNELID != '49a684a4-562e-4a66-bdd3-07f55b21381b' && <li onClick={() => {
              onClickItemFun({ url: 'https://ls.app.19930322.com/?channelid=49a684a4-562e-4a66-bdd3-07f55b21381b' })
            }}>
              <div className={`${Style.liIconWrapper}`}>
                <span className={`${Style.liIcon} ${Style.daba1} ${Style.c1}`}>
                  {/* <div className={`${Style.daba}`}></div> */}
                </span>
                <div className={`${Style.liLabel}`}>主屏</div>
              </div>
            </li>}
            <li onClick={() => {
              onClickItemFun({ url: 'http://qcsk.zjszsk.com:10011/project/login' })
            }}>
              <div className={`${Style.liIconWrapper}`}>
                <span className={`${Style.liIcon} ${Style.daba} ${Style.c1}`}>
                  {/* <div className={`${Style.daba}`}></div> */}
                </span>
                <div className={`${Style.liLabel}`}>大坝</div>
              </div>
            </li>
            {CHANNELID != '3e9b3774-78a1-4da7-8b92-c8d1b39aba60' && <li onClick={() => {
              onClickItemFun({ url: 'https://ls.app.19930322.com/?channelid=3e9b3774-78a1-4da7-8b92-c8d1b39aba60' })
            }}>
              <div className={`${Style.liIconWrapper}`}>
                <span className={`${Style.liIcon} ${Style.dianzhan}  ${Style.c1}`}>
                </span>
                <div className={`${Style.liLabel}`}>电站</div>
              </div>
            </li>}
            {CHANNELID != 'ca7df37d-7d18-85b9-6567-fc3c9bc84a9c' && <li onClick={() => {
              onClickItemFun({ url: 'https://ls.app.19930322.com/?channelid=ca7df37d-7d18-85b9-6567-fc3c9bc84a9c' })
            }}>
              <div className={`${Style.liIconWrapper}`}>
                <span className={`${Style.liIcon} ${Style.shuizhi} ${Style[`c${setWaterQualityColor(settingDataReal.setting.waterQuality)}`]}`}>
                  {convert(settingDataReal.setting.waterQuality)}类
                </span>
                <div className={`${Style.liLabel}`}>水质</div>
              </div>
            </li>}
            <li >
              <div className={`${Style.liIconWrapper}`}>
                <span className={`${Style.liIcon} ${Style.kuqu}  ${Style.c1}`}>
                </span>
                <div className={`${Style.liLabel}`}>库区</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
